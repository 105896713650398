<template>
  <t-form-card>
    <template #header>
      <h4>{{ $t('fields.password.change') }}</h4>
    </template>

    <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
      <t-form @submit.native.prevent="handleSubmit(submit)">
        <validation-provider
          v-slot="{ errors }"
          vid="current_password"
          :name="$t('fields.password.current')"
          rules="required"
        >
          <t-form-element>
            <t-form-label>{{ $t('fields.password.current') }}</t-form-label>
            <password-input v-model="form.current_password" />
            <span v-if="errors" class="text--danger">{{ errors[0] }}</span>
          </t-form-element>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          vid="password"
          :name="$t('fields.password.new')"
          rules="required|min:8|password"
        >
          <t-form-element>
            <t-form-label>{{ $t('fields.password.new') }}</t-form-label>
            <password-input v-model="form.password" />
            <span v-if="errors" class="text--danger">{{ errors[0] }}</span>
          </t-form-element>
        </validation-provider>

        <validation-provider
          v-slot="{ errors }"
          :name="$t('fields.password.new_confirmation')"
          rules="required|confirmed:password"
        >
          <t-form-element>
            <t-form-label>{{ $t('fields.password.new_confirmation') }}</t-form-label>
            <password-input v-model="confirmation" />
            <span v-if="errors" class="text--danger">{{ errors[0] }}</span>
          </t-form-element>
        </validation-provider>

        <t-form-element>
          <t-button-loading native-type="submit" :loading="loading" :disabled="invalid">
            {{ $t('global.actions.save') }}
          </t-button-loading>
        </t-form-element>
      </t-form>
    </validation-observer>
  </t-form-card>
</template>

<script>
import Vue from 'vue';
import TFormCard from '~/components/TFormCard.vue';
import PasswordInput from '~/components/PasswordInput.vue';

export default Vue.extend({
  components: { TFormCard, PasswordInput },
  data() {
    return {
      form: {
        current_password: null,
        password: null,
      },

      loading: false,
      confirmation: null,
    };
  },

  methods: {
    async submit() {
      this.loading = true;
      try {
        await this.$auth.changePassword(this.form);

        await this.$notify.success(this.$t('notifications.password.changed'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },
  },
});
</script>
